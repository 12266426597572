@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

body {
  @apply bg-dark text-gray-lighten overflow-x-hidden md:overflow-x-visible;
  font-family:sora;
  font-weight:700;
  background-color: rgb(1 1 1);
}
h1, h2, h3, h4, h5, h6 {
  font-family:sora;
  font-weight:700;
}
html {
  @apply overflow-x-hidden md:overflow-x-visible;

}

.\!text-primary {
    --tw-text-opacity: 1 !important;
    color: rgb(251 174 0) !important;
}


.rounded-full {
    border-radius: 0px;
}
.text-primary {
    --tw-text-opacity: 1;
    color: rgb(251 174 0);
}


.text-primary {
    --tw-text-opacity: 1;
    color: rgb(251 174 0);
}
.bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(251 174 0);
}
.border-primary {
    --tw-border-opacity: 1;
    border-color: rgb(251 174 0);
}
.from-primary {
    --tw-gradient-from: #fbae00;
    --tw-gradient-to: rgb(81 121 255 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.to-\[\#c353b4\] {
    --tw-gradient-to: #fbae00;
}


button, [type='button'], [type='reset'], [type='submit'] {
    -webkit-appearance: button;
    background-color: #0000002e;
    background-image: none;
}
.rounded-md {
    border-radius: 0rem;
}

/* CUSTOM UTILITIES */
.tw-pagination-btn {
  @apply inline-block h-8 w-8 bg-dark-lighten rounded-full tw-flex-center hover:bg-dark-lighten-2 transition duration-300;
}

.tw-flex-center {
  @apply flex justify-center items-center;
}

.tw-absolute-center {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
}

.tw-absolute-center-horizontal {
  @apply absolute left-1/2 -translate-x-1/2;
}

.tw-absolute-center-vertical {
  @apply absolute top-1/2 -translate-y-1/2;
}

.tw-black-backdrop {
  background: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.85) 65%);
}

.tw-black-backdrop-2 {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(179, 179, 193, 0) 7%,
    rgba(107, 107, 115, 0) 93%,
    rgba(0, 0, 0, 0.6) 100%
  );
}

.tw-black-backdrop-3 {
  background: linear-gradient(65deg, rgba(39, 42, 55, 1) 70%, transparent 140%);
}

.tw-multiline-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;

  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.tw-multiline-ellipsis-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;

  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tw-floating-label {
}

/* CUSTOM SLIDER */
:root {
  --swiper-theme-color: white !important;
  --swiper-navigation-size: 15px !important;

}

.tw-banner-slider .swiper-button-prev {
  @apply !top-[6%] !left-[2%] !z-20 hidden sm:block;
}

.tw-banner-slider .swiper-button-next {
  @apply !top-[6%] !left-[5%] !z-20 hidden sm:block;
}

.tw-section-slider .swiper-button-prev {
  --swiper-navigation-size: 25px !important;
  z-index: 20 !important;
}

.tw-section-slider .swiper-button-next {
  --swiper-navigation-size: 25px !important;
  z-index: 20 !important;
}

/* CUSTOM SCROLLBAR*/
::-webkit-scrollbar {
  @apply w-[10px] h-[10px];
}

::-webkit-scrollbar-track {
  @apply bg-transparent;
}

::-webkit-scrollbar-button {
  @apply hidden;
}

::-webkit-scrollbar-thumb {
  @apply bg-[#121212] rounded-full hover:bg-[#0e0d0d] transition duration-300;
}

select:focus {
  outline: none !important;
}

/* CUSTOM SLIDER RANGE */
.tw-slider-range::-webkit-slider-thumb {
  @apply appearance-none h-4 w-4 rounded-full bg-primary pointer-events-auto;
}

/* CUSTOM INPUT BACKGROUND WHEN AUTOFILL FORM */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #49494b inset !important;
}
