.App {
  text-align: center;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgb(28 28 30 / var(--tw-bg-opacity));
  background-image: url(./assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}
